import React, { useEffect, useState, useRef } from 'react'
import styles from './HomePage.module.css'
import Navbar from '../../components/navigation/Navbar'
import { ArrowIcon, CardIcon, GiftIcon, RoundWithArrowIcon, WarningIcon } from '../../assets/icons'
import { DiamondIcon, ShieldDiamondIcon, ShieldIcon, InfoIcon } from '../../assets/icons'
import TelegramAPI from '../../telegram'
import Modal from '../../components/Modal/Modal'
import { PageDescription, PageHeader } from '../../components/typography/Typography'
import BalanceCard from '../../components/user/BalanceCard/BalanceCard'
import TextInput from '../../components/forms/TextInput/TextInput'
import PrimaryButton from '../../components/buttons/PrimaryButton/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../contexts/userContext'
import { store } from '../../data'
import { formatDateWithoutYear } from '../../helpers'
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react'

import { Address } from '@ton/core'
import { serverLink } from '../../data/link-setting'
import DepositModal from './DepositModal/DepositModal'
import PPinModal from './PPinModal/PPinModal'

const TransactionCard = ({ item, onClick, modal = false }) => {
  const getColorStyles = (type, status) => {
    if (status === 'declined') return { background: 'rgba(209, 49, 49, 0.2)', color: 'rgb(209, 49, 49)' }
    if (status === 'partial_completed') return { background: 'rgba(255,255,255,0.2)', color: 'rgb(255,255,255)' }
    const incomeColor = { background: 'rgba(49, 209, 88, 0.2)', color: 'rgb(49, 209, 88)' }
    const outcomeColor = { background: 'rgba(209, 49, 49, 0.2)', color: 'rgb(209, 49, 49)' }

    switch (type) {
      case 'deposit':
      case 'refferal_claim':
      case 'comission':
      case 'system':
      case 'bonus':
      case 'gift':
        return incomeColor
      case 'withdrawal':
      case 'purchase':
        return outcomeColor
      default:
        return {}
    }
  }

  const getTextColorStyles = (type, status) => {
    const color = getColorStyles(type, status).color
    return { color: color }
  }

  const getTitle = (type, additional, user, amount) => {
    const titles = {
      refferal_claim: `Rewards ${user?.name ? `from ${user.name}` : ''}`,
      withdrawal: `Withdrawal`,
      deposit: 'Deposit',
      purchase: `Purchased ${additional?.name ? `${additional.name}` : ''}`,
      comission: 'Received comission',
      system: 'System gift',
      bonus: `Gift from another user withdrawal`,
    }
    return titles[type] || ''
  }

  const getSubtitle = (type, status, item) => {
    if (type === 'purchase') return 'Store'
    if (type === 'withdrawal') return item?.withdraw?.status ? item?.withdraw?.status : 'Sent'
    if (status === 'partial_completed') return 'Partial Received'
    if (status === 'declined') return 'Missed profit'
    return 'Received'
  }

  const handleOnClick = () => {
    if (!modal && item.description) {
      onClick(item)
    }
  }

  return (
    <div className={styles.transactionCard} onClick={handleOnClick}>
      <div className={styles.transactionLeft}>
        <div
          className={styles.transactionIconHolder}
          style={
            item?.user?.photo_id
              ? {
                  background: `url(${serverLink}/user-photo/${item?.user.photo_id})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }
              : getColorStyles(item.type, item.status)
          }>
          {item.type === 'refferal_claim' && !item?.user?.photo_id && item?.user?.name.slice(0, 1)}
          {item.type === 'withdrawal' && <ArrowIcon />}
          {item.type === 'purchase' && <CardIcon />}
          {item.type === 'deposit' && <ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
          {item.type === 'comission' && !item?.user?.photo_id && item?.user?.name.slice(0, 1)}
          {(item.type === 'system' || item.type === 'bonus') && <GiftIcon />}
        </div>
        <div className={styles.transactionInfo}>
          <div className={styles.transactionInfo__title}>
            {getTitle(item.type, item.additional, item.user, item.amount)}
            {!modal && (
              <span>
                <InfoIcon width={14} height={14} style={{ marginLeft: 2, paddingTop: 1 }} />
              </span>
            )}
          </div>
          <div className={styles.transactionInfo__date}>{formatDateWithoutYear(item.transaction_date)}</div>
        </div>
      </div>
      <div className={styles.transactionRight}>
        <div className={styles.transactionRight} style={getTextColorStyles(item.type, item.status)}>
          <div className={styles.transactionRight__sum}>
            {item.type === 'withdrawal' || item.type === 'purchase'
              ? '-'
              : item.type === 'refferal_claim' && item.status === 'declined'
              ? ''
              : '+'}
            {parseFloat(item.amount.toFixed(2))} USDT
          </div>
          <div className={styles.transactionRight__subtitle}>{getSubtitle(item.type, item.status, item)}</div>
        </div>
      </div>
    </div>
  )
}

export default function HomePage() {
  const [tonConnectUI, setOptions] = useTonConnectUI()
  const [appovePin, setAppovePin] = useState(0)
  const [amount, setAmount] = useState(0)
  const { user, withdraw, error } = useUser()
  const [mode, setMode] = useState(null)
  const [transactionsDetails, setTransactionsDetails] = useState(null)
  const navigate = useNavigate()

  const getWalletAdress = (connect) => {
    const rawAddress = connect.account.address
    const normalizedAddress = Address.parse(rawAddress).toString({ urlSafe: true })
    return normalizedAddress
  }

  const handleOpenDeposit = () => {
    TelegramAPI.Vibrate('heavy')
    setMode('deposit')
  }
  const handleOpenWithdraw = () => {
    TelegramAPI.Vibrate('heavy')
    setMode('withdraw')
  }

  const handleWithdraw = async () => {
    TelegramAPI.Vibrate('heavy')
    // const result = await withdraw(parseFloat(amount), address)
    // TelegramAPI.ShowAlert(result.message)
    // handleCloseRef()
    setAppovePin(1)
  }

  const handleSelectTransactions = (item) => {
    TelegramAPI.Vibrate('heavy')
    setTransactionsDetails(item)
  }

  const handleClose = () => {
    TelegramAPI.Vibrate('heavy')
    setMode(null)
  }

  const modalRef = useRef(null)

  const handleCloseRef = () => {
    modalRef.current.close()
  }

  const handleNavigateToShop = () => {
    TelegramAPI.Vibrate('heavy')
    navigate('/shop')
  }

  const [selectedPlan, setSelectedPlan] = useState({})
  useEffect(() => {
    if (user && store) {
      setSelectedPlan(store.find((item) => item.id === user.status_id))
    }
  }, [user, store])

  const getWithdrawButtonText = (connect, amount) => {
    if (!tonConnectUI?.account?.address) {
      return 'Link your Wallet'
    }
    if (amount < 5) {
      return 'Minimum 5$'
    }
    if (amount > user?.balance) {
      return 'Insufficient funds'
    }
    return `Withdraw ${amount - amount * 0.1}$`
  }

  if (user && !selectedPlan?.background && error) {
    return (
      <div className={styles.container}>
        <PageHeader style={{ marginBottom: 24 }}>Error</PageHeader>
        <PageDescription>Try sending /start to the bot again.</PageDescription>
      </div>
    )
  }
  if (selectedPlan?.background) {
    return (
      <div className={styles.container}>
        <div className={styles.statusContainer} style={{ background: selectedPlan.background }}>
          {!user?.status_id && (
            <div>
              <div>
                <WarningIcon />
                You don't have a status yet
              </div>
            </div>
          )}
          {!user?.status_id && (
            <div className={styles.statusContainer__button} onClick={handleNavigateToShop}>
              Buy status
            </div>
          )}
          {user?.status_id !== 0 && (
            <div className={styles.statusContainer__content}>
              {selectedPlan.lvl === 1 && <ShieldIcon height={29} style={{ color: `rgb(${selectedPlan?.color})` }} />}
              {selectedPlan.lvl === 2 && <DiamondIcon height={29} style={{ color: `rgb(${selectedPlan?.color})` }} />}
              {selectedPlan.lvl === 3 && (
                <ShieldDiamondIcon
                  height={57}
                  style={{ color: `rgb(${selectedPlan?.color})` }}
                  pink={selectedPlan.id === 10 && true}
                />
              )}
              <div className={styles.statusName} style={{ color: `rgb(${selectedPlan.color})` }}>
                {selectedPlan.name}
              </div>
            </div>
          )}
        </div>
        <div className={styles.balanceContainer}>
          <div className={styles.balanceContainer__title}>Your Balance</div>
          <div className={styles.balanceContainer__text}>${parseFloat(user?.balance.toFixed(2))}</div>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonsContainer__button} onClick={handleOpenDeposit}>
            <RoundWithArrowIcon className={`${styles.buttonsContainer__icon} ${styles.buttonsContainer__iconRotate}`} />
            <div className={styles.buttonsContainer__text}>Deposit</div>
          </div>
          <div className={styles.buttonsContainer__button} onClick={handleOpenWithdraw}>
            <RoundWithArrowIcon className={styles.buttonsContainer__icon} />
            <div className={styles.buttonsContainer__text}>Withdraw</div>
          </div>
        </div>
        <div className={styles.transactionsSection}>
          <div className={styles.transactionsSubheader}>transaction history</div>

          <div className={styles.transactionsContainer}>
            {user?.transactions && user?.transactions.length > 0 ? (
              user?.transactions.map((item, index) => (
                <TransactionCard item={item} key={index} onClick={handleSelectTransactions} />
              ))
            ) : (
              <div className={styles.warningMessage}>
                <div className={styles.warningMessage_icon}>
                  <WarningIcon width={24} height={24} />
                </div>
                <div className={styles.warningMessage_content}>
                  <div className={styles.warningMessage_content__title}>History is empty</div>
                  <div className={styles.warningMessage_content__text}>
                    You haven't made any transactions yet. Hurry up to join so as not to lose possible profits
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {mode !== null && mode === 'deposit' && <DepositModal handleClose={handleClose} />}

        {mode !== null && mode === 'withdraw' && (
          <Modal ref={modalRef} title="Withdraw" onClose={handleClose} fullScreen={true}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <PageHeader>Withdraw</PageHeader>
              </div>
              <BalanceCard />
              <div className={styles.form}>
                <div className={styles.tonConnect}>
                  <TonConnectButton />
                </div>

                {/* <TextInput label={'Adress'} value={Address.parse(tonConnectUI?.account.address)}></TextInput> */}
                <div>
                  <TextInput
                    label={'Amount'}
                    value={amount}
                    type={'float'}
                    setValue={(e) => setAmount(e.target.value)}></TextInput>
                  <p className={styles.comment}>* Comission 10%</p>
                </div>
              </div>
            </div>
            <div className={styles.modalButtons}>
              <PrimaryButton onClick={handleCloseRef}>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.70711 0.792893C9.31658 0.402369 8.68342 0.402369 8.29289 0.792893L0.792893 8.29289C0.402369 8.68342 0.402369 9.31658 0.792893 9.70711L8.29289 17.2071C8.68342 17.5976 9.31658 17.5976 9.70711 17.2071C10.0976 16.8166 10.0976 16.1834 9.70711 15.7929L2.91421 9L9.70711 2.20711C10.0976 1.81658 10.0976 1.18342 9.70711 0.792893Z"
                    fill="#000"
                  />
                </svg>
              </PrimaryButton>
              <PrimaryButton
                onClick={
                  amount >= 5 && amount <= user?.balance && tonConnectUI?.account?.address
                    ? () => handleWithdraw(amount, getWalletAdress(tonConnectUI))
                    : () => {}
                }
                style={
                  amount < 5 || amount > user?.balance || !tonConnectUI?.account?.address
                    ? { color: '#fff', background: 'transparent' }
                    : {}
                }>
                {getWithdrawButtonText(tonConnectUI, amount)}
              </PrimaryButton>
            </div>
          </Modal>
        )}

        {transactionsDetails && (
          <Modal title="Transaction details" onClose={() => setTransactionsDetails(null)}>
            <TransactionCard item={transactionsDetails} modal={true} />
            <div className={styles.transactionsModal__info}>
              <InfoIcon width={16} height={16} className={styles.transactionsModal__info_icon} />{' '}
              {transactionsDetails.description}
            </div>
          </Modal>
        )}
        {user && !user.settedPin && <PPinModal handleClose={() => {}} type={'create'} />}
        {appovePin === 1 && user && (
          <PPinModal
            handleClose={() => {
              handleCloseRef()
              setAppovePin(false)
            }}
            amount={amount}
            address={getWalletAdress(tonConnectUI)}
          />
        )}

        <Navbar active={'profile'} />
      </div>
    )
  }
}
