import React, { useState, useEffect } from 'react'
import { Buffer } from 'buffer'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

import './App.css'
import FriendsPage from './pages/FriendsPage'
import HomePage from './pages/HomePage'
import ShopPage from './pages/ShopPage'
import TelegramAPI from './telegram'
import { UserProvider } from './contexts/userContext'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import startVideo from './assets/start.mp4'
import { TonClientProvider } from './contexts/ton-client-context'
import { PageDescription, PageHeader } from './components/typography/Typography'
import Activity from './pages/Activity'

function AnimatedRoutes() {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route path="/" element={<HomePage />} />
      <Route path="/friends" element={<FriendsPage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/activity" element={<Activity />} />
    </Routes>
  )
}

function App() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true)

  // Обработчик завершения видео
  const handleVideoEnd = () => {
    setIsVideoPlaying(false)
  }

  useEffect(() => {
    window.Buffer = Buffer
    TelegramAPI.expand()
    TelegramAPI.SetBackgroundColor('#000')
    TelegramAPI.SetHeaderColor('#000')
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.disableVerticalSwipes()
    }
  }, [])

  const manifestUrl = 'https://cashcode.biz/tonconnect-manifest.json'

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <TonClientProvider>
        <UserProvider>
          <Router>
            {isVideoPlaying && (
              <>
              {/* <button className='skip-video-button' onClick={() => setIsVideoPlaying(false)}> &times;</button> */}
              <button className='skip-video-button-text' onClick={() => setIsVideoPlaying(false)}>Skip...</button>
              <video autoPlay muted playsInline onEnded={handleVideoEnd} className="fullscreen-video">
                <source src={startVideo} type="video/mp4" />
                Can`t load video
              </video>
              </>
            )}
            <AnimatedRoutes />
          </Router>
        </UserProvider>
      </TonClientProvider>
    </TonConnectUIProvider>
    // <div className="main-container">
    //   <PageHeader>Maintenance in Progress</PageHeader>
    //   <PageDescription>
    //     We apologize for the inconvenience. Unscheduled maintenance is currently underway. Please follow the bot for
    //     updates on the status of the work.
    //   </PageDescription>
    // </div>
  )
}

export default App
